import { alpha } from '@theme-ui/color'

const desktopTabletMinHeight = '36em'
const tabletMinWidth = '48em'
const desktopMinWidth = '70em'

const breakpoints = [
  `@media screen and (min-width: ${tabletMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
  `@media screen and (min-width: ${desktopMinWidth}) and (min-height: ${desktopTabletMinHeight})`,
]

const colors = {
  neonGreen: '#55FF8A',
  neonGreen50: alpha('neonGreen', 0.5),
  neonGreen25: alpha('neonGreen', 0.25),
  neonGreen15: alpha('neonGreen', 0.15),
  neonGreen10: alpha('neonGreen', 0.1),
  windscribeBlue: '#006aff',
  windscribeBlue50: alpha('windscribeBlue', 0.5),
  windscribeBlue25: alpha('windscribeBlue', 0.25),
  windscribeBlue10: alpha('windscribeBlue', 0.1),
  midnight: '#030407',
  bodyBG: '#041d3e',
  bodyBG0: alpha('bodyBG', 0),
  midnight25: alpha('midnight', 0.25),
  red: '#ff3b3b',
  yellow: '#ffef02',
  yellow10: alpha('yellow', 0.1),
  periwinkle: '#8d6cff',
  periwinkle50: alpha('periwinkle', 0.5),
  periwinkle25: alpha('periwinkle', 0.25),
  periwinkle10: alpha('periwinkle', 0.1),
  white: '#ffffff',
  gold: '#ffda44',
  white50: alpha('white', 0.5),
  white25: alpha('white', 0.25),
  white20: alpha('white', 0.2),
  white15: alpha('white', 0.15),
  white10: alpha('white', 0.1),
  //Added the transparent color for the linear gradient
  transparent: alpha('white', 0),
  white05: alpha('white', 0.05),
  brightYellow: '#ffda44',
  background: '#090E19',
  kuretakeBlack: '#030e1d',
  kuretakeBlack0: alpha('#030e1d', 0),
  white75: alpha('white', 0.75),
  orange: '#FF9255',
  orange10: alpha('orange', 0.1),
  salmon: '#F55',
  salmon10: alpha('salmon', 0.1),
  darkBlue: '#0A0B0D',
  red10: alpha('red', 0.1),
  red20: alpha('red', 0.2),
  contentBackground: '#090E19',
  heroGradientStart: '#030407',
  heroGradientEnd: alpha('#030407', 0),
}

const radii = {
  rounded: '999999px',
}

const transitions = {
  default: 'all 0.1s ease-in-out',
}

const variants = {
  styles: {
    a: {
      textDecoration: 'none',
      display: 'flex',
      color: 'white50',
      transition: transitions.default,
      ':focus-visible,button:focus-visible': {
        outline: '-webkit-focus-ring-color auto 1px',
      },
      ':hover': {
        color: 'white',
      },
    },
  },
  buttons: {
    link: {
      textDecoration: 'underline',
      width: '100%',
      display: 'flex',
      color: 'white50',
      transition: transitions.default,
      ':focus-visible,button:focus-visible': {
        outline: '-webkit-focus-ring-color auto 1px',
      },
      ':hover': {
        color: 'white',
        cursor: 'pointer',
      },
      backgroundColor: 'transparent',
      p: 0,
      m: 0,
    },
    primary: {
      cursor: 'pointer',
    },
    neon: {
      cursor: 'pointer',
      borderRadius: '4.6rem',
      backgroundColor: 'neonGreen25',
      px: '2.4rem',
      py: '1.2rem',
      color: 'neonGreen',
      fontWeight: 'bold',
      fontSize: '1.8rem',
      transition: transitions.default,
      ':hover': {
        borderColor: 'neonGreen',
        backgroundColor: 'neonGreen',
        color: 'bodyBG',
      },
      ':disabled': {
        borderColor: 'white25',
        backgroundColor: 'transparent',
        color: 'white25',
        cursor: 'not-allowed',
      },
      ':not(:hover):visited': {
        color: 'neonGreen',
      },
    },
    neonGreen: {
      cursor: 'pointer',
      borderRadius: '4.6rem',
      border: 'none',
      backgroundColor: 'neonGreen',
      px: '2.4rem',
      py: '1.2rem',
      color: 'bodyBG',
      fontWeight: 'bold',
      fontSize: '1.8rem',
      transition: transitions.default,
      '& svg path': {
        transition: transitions.default,
        fill: 'black',
      },
      ':hover': {
        backgroundColor: 'neonGreen15',
        color: 'neonGreen',
        '& svg path': {
          fill: 'neonGreen',
        },
      },
      ':disabled': {
        transition: '0s',
        borderColor: 'white25',
        backgroundColor: 'transparent',
        color: 'white25',
        cursor: 'not-allowed',
      },
      ':not(:hover):visited': {
        color: 'bodyBG',
      },
    },
    outlined: {
      borderRadius: '4.8rem',
      border: ({ colors }) => `2px solid ${colors?.white15}`,
      color: 'white50',
      textDecoration: 'none',
      fontWeight: 'bold',
      fontSize: '1.8rem',
    },
  },
  text: {
    paragraphSmallest: {
      fontSize: '1.2rem',
    },
    paragraphSmallBold: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      lineHeight: '2.4rem',
    },
    paragraphSmallRegular: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    paragraphBigRegular: {
      fontSize: '1.8rem',
      lineHeight: '2.8rem',
    },
    pillsNormal: {
      fontSize: '1.4rem',
    },
    h1Big: {
      fontSize: '4rem',
      fontWeight: 'bold',
    },
    h1Small: {
      fontSize: '3.2rem',
      fontWeight: 'bold',
    },
    h3BigBold: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '2.1rem',
      lineHeight: '2.7rem',
    },
    body2: {
      fontSize: '1.8rem',
      lineHeight: '2.7rem',
    },
    body3: {
      fontSize: '1.6rem',
    },
    heading1: {
      fontSize: '4.8rem',
      fontWeight: 'bold',
    },
    heading2: {
      fontSize: '2.1rem',
      fontWeight: 'bold',
      lineHeight: '2.7rem',
    },
    heading3: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: 'tag',
      fontSize: '1.4rem',
    },
    button: {
      fontSize: '1.8rem',
      fontWeight: 'bold',
    },
  },
}

const sizes = {
  maxContentWidth: '98rem',
}

const letterSpacings = {
  tag: '0.375rem',
}

const space = {
  default: ['4.8rem', '8rem'],
}

export default {
  breakpoints,
  colors,
  radii,
  transitions,
  sizes,
  letterSpacings,
  space,
  ...variants,
}
